import Vue from 'vue'
import VueRouter from 'vue-router'
import PageAdmin from '../ui/pages/PageAdmin.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "login" */ '../ui/pages/PageLogin.vue'),
  },
  {
    path: '/home',
    name: 'PageHome',
    component: PageAdmin,
    meta: {
      mainBottomNavigation: true,
    },
  },
  {
    path: '/other',
    name: 'PageOther',
    component: () => import(/* webpackChunkName: "other" */ '../ui/pages/PageOther.vue'),
    meta: {
      mainBottomNavigation: true,
    },
  },

  {
    path: '/correction',
    name: 'PageCorrection',
    component: () => import(/* webpackChunkName: "correction" */ '../ui/pages/PageCorrection.vue'),
  },

  // {
  //   path: '/profile',
  //   name: 'Profile',
  //   component: () => import(/* webpackChunkName: "profile" */ '../ui/pages/PageProfile.vue'),
  //   meta: {
  //     title: 'Профиль',
  //   },
  // },
  // {
  //   path: '/profile/edit',
  //   name: 'ProfileEdit',
  //   component: () => import(/* webpackChunkName: "profile-edit" */ '../ui/pages/PageProfileEdit.vue'),
  //   meta: {
  //     title: 'Редактирование профиля',
  //   },
  // },
  {
    path: '/profile/change-password',
    name: 'ProfileChangePassword',
    component: () => import(/* webpackChunkName: "profile-change-password" */ '../ui/pages/PageProfileChangePassword.vue'),
    meta: {
      title: 'Изменение пароля',
    },
  },

  {
    path: '/projects',
    name: 'PageProjects',
    component: () => import(/* webpackChunkName: "projects" */ '../ui/pages/PageProjects.vue'),
    meta: {
      title: 'Проекты',
    },
  },
  {
    path: '/description',
    name: 'PageProjectEmptyDescription',
    component: () => import(/* webpackChunkName: "project-description" */ '../ui/pages/PageProjectDescription.vue'),
    meta: {
      title: 'Описание проекта',
    },
  },
  {
    path: '/questions',
    name: 'PageProjectEmptyQuestions',
    component: () => import(/* webpackChunkName: "project-questions" */ '../ui/pages/PageProjectQuestions.vue'),
    meta: {
      title: 'Вопросы для отбора',
    },
  },
  {
    path: '/candidates',
    name: 'PageProjectEmptyCandidates',
    component: () => import(/* webpackChunkName: "project-candidates" */ '../ui/pages/PageProjectCandidates.vue'),
    meta: {
      title: 'Отбор кандидатов',
    },
  },
  {
    path: '/projects/:projectId',
    name: 'PageProject',
    component: () => import(/* webpackChunkName: "project" */ '../ui/pages/PageProject.vue'),
    meta: {
      title: 'Страница проекта',
    },
  },
  {
    path: '/projects/:projectId/description',
    name: 'PageProjectDescriptionView',
    // component: () => import(/* webpackChunkName: "project-description" */ '../ui/pages/PageProjectDescription.vue'),
    component: () => import(/* webpackChunkName: "project-description-view" */ '../ui/pages/PageProjectDescriptionView.vue'),
    meta: {
      title: 'Описание проекта',
    },
  },
  {
    path: '/projects/:projectId/questions',
    name: 'PageProjectQuestions',
    component: () => import(/* webpackChunkName: "project-questions" */ '../ui/pages/PageProjectQuestions.vue'),
    meta: {
      title: 'Вопросы для отбора',
    },
  },
  {
    path: '/projects/:projectId/candidates',
    name: 'PageProjectCandidates',
    component: () => import(/* webpackChunkName: "project-candidates" */ '../ui/pages/PageProjectCandidates.vue'),
    meta: {
      title: 'Отбор кандидатов',
    },
  },

  {
    path: '/projects/:projectId/candidates/table',
    name: 'PageCandidateAnalysisSingle',
    component: () => import(/* webpackChunkName: "candidates-estimation-table" */ '../ui/pages/PageCandidateAnalysisSingle.vue'),
    meta: {
      title: 'Анализ кандидатов',
    },
  },

  {
    path: '/vacancies',
    name: 'PageVacancies',
    component: () => import(/* webpackChunkName: "vacancies" */ '../ui/pages/PageVacancies.vue'),
    meta: {
      title: 'Вакансии',
    },
  },
  {
    path: '/new-vacancy',
    name: 'PageVacancyNew',
    component: () => import(/* webpackChunkName: "new-vacancy" */ '../ui/pages/PageVacancyNew.vue'),
    meta: {
      title: 'Новая вакансия',
    },
  },
  {
    path: '/new-vacancy/edit',
    name: 'PageVacancyDataNew',
    component: () => import(/* webpackChunkName: "vacancy-data-new" */ '../ui/pages/PageVacancyDataEdit.vue'),
    meta: {
      title: 'Редактирование вакансии',
    },
  },
  {
    path: '/vacancies/:vacancyId',
    name: 'PageVacancy',
    component: () => import(/* webpackChunkName: "vacancy" */ '../ui/pages/PageVacancy.vue'),
    meta: {
      title: 'Вакансия',
    },
  },
  {
    path: '/vacancies/:vacancyId/edit',
    name: 'PageVacancyDataEdit',
    component: () => import(/* webpackChunkName: "vacancy-data-edit" */ '../ui/pages/PageVacancyDataEdit.vue'),
    meta: {
      title: 'Редактирование вакансии',
    },
  },
  {
    path: '/vacancies/:vacancyId/description',
    name: 'PageVacancyData',
    component: () => import(/* webpackChunkName: "vacancy-description-view" */ '../ui/pages/PageVacancyData.vue'),
    meta: {
      title: 'Описание вакансии',
    },
  },
  {
    path: '/vacancies/:vacancyId/candidates',
    name: 'PageVacancyCandidates',
    component: () => import(/* webpackChunkName: "vacancy-candidates" */ '../ui/pages/PageVacancyCandidates.vue'),
    meta: {
      title: 'Кандидаты',
    },
  },

  {
    path: '/settings',
    name: 'Settings',
    component: () => import(/* webpackChunkName: "settings" */ '../ui/pages/PageSettings.vue'),
    meta: {
      title: 'Настройки',
    },
  },

  {
    path: '/users',
    name: 'PageUsers',
    component: () => import(/* webpackChunkName: "users" */ '../ui/pages/PageUsers.vue'),
    meta: {
      title: 'Пользователи',
    },
  },

  {
    path: '/companies',
    name: 'PageCompanies',
    component: () => import(/* webpackChunkName: "companies" */ '../ui/pages/PageCompanies.vue'),
    meta: {
      title: 'Компании',
    },
  },

  {
    path: '/company',
    name: 'PageCompany',
    component: () => import(/* webpackChunkName: "company" */ '../ui/pages/PageCompany.vue'),
    meta: {
      title: 'Ваша компания',
    },
  },

  {
    path: '/candidates-estimation',
    name: 'PageCandidateAnalysis',
    component: () => import(/* webpackChunkName: "candidates-estimation" */ '../ui/pages/PageCandidateAnalysis.vue'),
    meta: {
      title: 'Анализ кандидатов',
    },
  },

  {
    path: '/dev',
    name: 'Dev',
    component: () => import(/* webpackChunkName: "dev" */ '../ui/pages/PageDev.vue'),
    meta: {
      mainBottomNavigation: true,
      title: 'Dev',
    },
  },
  {
    path: '/dev/hh',
    name: 'DevHeadHunter',
    component: () => import(/* webpackChunkName: "dev" */ '../ui/pages/PageDevHeadHunter.vue'),
    meta: {
      title: 'DevHeadHunter',
    },
  },
  {
    path: '/',
    redirect: '/home',
  },
  {
    path: '/*',
    redirect: '/',
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL || '/',
  routes,
});

// TODO: scroll top

export default router;
