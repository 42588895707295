<template>
  <!--    900-->
  <LayoutOneColumn :maxWidth="850">
    <div
      class="d-flex flex-column"
    >

<!--      <CardLink-->
<!--        title="Коррекция текста"-->
<!--        description="Сервис коррекции текста"-->
<!--        icon="mdi-text-box"-->
<!--        to="/correction"-->
<!--        class="ma-1"-->
<!--        :dense="true"-->
<!--        :block="true"-->
<!--        style="width: calc(100% - 8px)"-->
<!--      />-->

<!--      <CardLink-->
<!--        title="Анализ кандидатов"-->
<!--        description="Панель аналитики кандидатов на проекты"-->
<!--        icon="mdi-chart-histogram"-->
<!--        to="/candidates-estimation"-->
<!--        class="ma-1"-->
<!--        :dense="true"-->
<!--        :block="true"-->
<!--        style="width: calc(100% - 8px)"-->
<!--      />-->

<!--      <CardLink-->
<!--        v-if="$store.persist.d.role === 'admin' || $store.persist.d.role === 'super' || $store.persist.d.role === 'dev'"-->
<!--        title="Управление пользователями"-->
<!--        description="Админ панель для управления данными пользователей"-->
<!--        icon="mdi-account-group"-->
<!--        to="/users"-->
<!--        class="ma-1"-->
<!--        :dense="true"-->
<!--        :block="true"-->
<!--        style="width: calc(100% - 8px)"-->
<!--      />-->

<!--      <CardLink-->
<!--        v-if="$store.persist.d.role === 'super' || $store.persist.d.role === 'dev'"-->
<!--        title="Управление компаниями"-->
<!--        description="Админ панель для управления данными компаний"-->
<!--        icon="mdi-domain"-->
<!--        to="/companies"-->
<!--        class="ma-1"-->
<!--        :dense="true"-->
<!--        :block="true"-->
<!--        style="width: calc(100% - 8px)"-->
<!--      />-->

<!--      <CardLink-->
<!--        v-if="$store.persist.d.companyId"-->
<!--        title="Профиль компании"-->
<!--        description="Страницы с данными вашей компании"-->
<!--        icon="mdi-office-building"-->
<!--        to="/company"-->
<!--        class="ma-1"-->
<!--        :dense="true"-->
<!--        :block="true"-->
<!--        style="width: calc(100% - 8px)"-->
<!--      />-->

<!--      <LayoutCards :block="true" class="mb-0">-->
<!--        <CardLink-->
<!--          title="Добавить проект"-->
<!--          description="Заполнить описание проекта"-->
<!--          icon="mdi-briefcase"-->
<!--          to="/description"-->
<!--          class="ma-1"-->
<!--        />-->
<!--        <CardLink-->
<!--          title="Вопросы для отбора"-->
<!--          description="Сгенерировать дополнительные вопросы для кандидатов"-->
<!--          icon="mdi-help"-->
<!--          to="/questions"-->
<!--          class="ma-1"-->
<!--        />-->
<!--        <CardLink-->
<!--          title="Отбор кандидатов"-->
<!--          description="Оценить и отранжировать кандидатов"-->
<!--          icon="mdi-account-search"-->
<!--          to="/candidates"-->
<!--          class="ma-1"-->
<!--        />-->
<!--      </LayoutCards>-->

      <v-tabs v-model="tab">
        <v-tab>Вакансии</v-tab>
        <v-tab>Проекты</v-tab>
      </v-tabs>


      <v-tabs-items v-model="tab">
        <v-tab-item>
          <CardLink
            title="Добавить вакансию"
            :description="employerId ? 'Импорт и загрузка по ссылке из headhunter' : 'Подключите свой аккаунт HH в настройках профиля'"
            icon="mdi-briefcase"
            to="/new-vacancy"
            class="ma-1"
            :dense="true"
            :block="true"
            :class="employerId ? '' : 'error'"
          />

          <div class="d-flex justify-space-between align-center mx-1">
            <h3 class="">Мои вакансии</h3>
            <v-btn text to="/vacancies" style="padding-right: 0; padding-left: 4px;">
              Открыть все
              <v-icon size="18" style="padding: 2px 2px 0 2px;">mdi-arrow-right</v-icon>
            </v-btn>
          </div>

          <template v-if="vacanciesMy && vacanciesMy.length">
            <CardVacancy
              v-for="vacancy of vacanciesSorted"
              :key="vacancy.id"
              :item="vacancy"
              @delete="removeRole(vacancy)"
              class="mx-1"
            />
          </template>
          <div v-else class="text-center pb-4" style="width: 100%">
            У вас нет ни одной вакансии
          </div>
        </v-tab-item>

        <v-tab-item>
          <CardLink
            title="Добавить проект"
            description="Заполнить описание проекта"
            icon="mdi-briefcase"
            to="/description"
            class="ma-1"
            :dense="true"
            :block="true"
          />

          <div class="mb-2">
            <div class="d-flex justify-space-between align-center mx-1">
              <h3 class="">Мои проекты</h3>
              <v-btn text to="/projects" style="padding-right: 0; padding-left: 4px;">
                Открыть все
                <v-icon size="18" style="padding: 2px 2px 0 2px;">mdi-arrow-right</v-icon>
              </v-btn>
            </div>

            <template v-if="projectsMy && projectsMy.length">
              <CardProject
                v-for="project of projectsSorted"
                :key="project.id"
                :name="project.name"
                :description="project.description"
                :extId="project.ext_id"
                :code="project.code"
                :createdAt="project.createdAt"
                :updatedAt="project.updatedAt"
                :status="project.status"
                :id="project.id"
                class="mx-1"
              />
            </template>
            <div v-else class="text-center pb-4" style="width: 100%">
              У вас нет ни одного проекта
            </div>
          </div>
        </v-tab-item>
      </v-tabs-items>



<!--      <v-alert-->
<!--        text-->
<!--        color="info"-->
<!--        icon="mdi-information"-->
<!--        class="mx-1"-->
<!--      >-->
<!--        <h3>Для продолжения работы на другом устройстве, используйте эту ссылку</h3>-->
<!--        <div class="d-flex align-center">-->
<!--          <a :href="link" target="_blank">{{ link }}</a>-->
<!--          <v-btn v-if="canCopy" icon color="primary" class="ml-1" @click="copyLink">-->
<!--            <v-icon size="18">mdi-content-copy</v-icon>-->
<!--          </v-btn>-->
<!--        </div>-->
<!--        <div class="body-1">Рекомендуем сохранить эту ссылку, чтобы не потерять доступ к своим данным в-->
<!--          приложении-->
<!--        </div>-->
<!--      </v-alert>-->
    </div>
  </LayoutOneColumn>
</template>

<script>
import LayoutOneColumn from '@/ui/layouts/LayoutOneColumn';
import CardLink from "../components/CardLink.vue";
import CardProject from "../components/CardProject.vue";
import CardVacancy from "../components/CardVacancy.vue";
// import CardButton from "../components/CardButton.vue";

export default {
  name: 'PageAdmin',
  components: {CardProject, CardVacancy, CardLink, LayoutOneColumn},
  data: () => ({
    projectsMy: null,
    vacanciesMy: null,

    tab: null,
  }),
  computed: {
    canCopy() {
      return !!navigator?.clipboard?.writeText;
    },
    link() {
      return location.href;
    },
    projectsSorted() {
      if (!this.projectsMy?.length) {
        return null;
      }
      return this.projectsMy?.toSorted((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
    },
    vacanciesSorted() {
      if (!this.vacanciesMy?.length) {
        return null;
      }
      return this.vacanciesMy?.toSorted((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
    },

    employerId() {
      return this.$api.hh.employerId.value;
    },
    employer() {
      return this.$api.hh.employerData;
    },
  },
  methods: {
    snackDev() {
      this.$api.app.snackDev();
    },
    async copyLink() {
      try {
        await navigator.clipboard.writeText(this.link);
        this.$api.app.snackInfo('Ссылка скопирована');
      } catch (e) {
        console.error(e);
        this.$api.app.snackError('Не удалось выполнить копирование ссылки');
      }
    },
    editProfile() {
      this.$store.main.d.isShowProfile = true;
      setTimeout(() => {
        this.$store.main.d.isShowProfileEditor = true;
      }, 300);
    },
    async removeRole(role) {
      await this.$api.main.deleteRequest('models/project/role', {role_id: role.id});
      await this.fetchData();
    },
    async fetchData() {
      // TODO: loading and error
      this.projectsMy = (await this.$api.main.getRequest('models/projects/my')).data;
      this.vacanciesMy = (await this.$api.main.getRequest('vacancies/my')).data;
    },
  },
  async created() {
    await this.fetchData();
  },
};
</script>

<style>
.theme--dark.v-tabs-items {
  background-color: inherit;
}

.card-link.error .card-button__description {
  color: var(--v-error-base) !important;
  /*font-weight: 600;*/
}
</style>
