<template>
    <LayoutOneColumn :maxWidth="600">

        <div class="mt-4">
            <h3>Настройки</h3>
            <v-switch
                    v-model="$store.persist.d.isThemeDark"
                    label="Темная тема"
                    hide-details
            />

            <v-btn
                    @click="removeLocalData"
                    depressed
                    color="warning"
                    class="mt-4"
            >Удалить локальные данные
            </v-btn>
        </div>

        <div class="my-4" v-if="$store.main.s.app === 'admin'">
            <h3>Аккаунт</h3>

            <div class="mt-2" v-if="user">
              <div>
                <b>Имя</b> {{user.name}}
              </div>
              <div>
                <b>Логин</b> {{user.login}}
              </div>
            </div>
            <div class="mt-4">
<!--                <v-btn-->
<!--                        depressed-->
<!--                        to="/profile/change-password"-->
<!--                        color="warning"-->
<!--                >-->
<!--                    Поменять пароль-->
<!--                </v-btn>-->
                <v-btn
                        depressed
                        color="error"
                        @click="signOut"
                >
                    Выйти из аккаунта
                </v-btn>
            </div>
        </div>

      <div class="my-4" v-if="user">
        <h3>Запуски ИИ</h3>
        <div v-if="user.llm_runs_count !== undefined" class="mt-4">
          <div class="caption">Количество завершенных запусков ИИ:</div>
          <div>{{user.llm_runs_count}}</div>
        </div>
        <div v-if="user.llm_runs_limits_count !== undefined" class="mt-4">
          <div class="caption">Количество доступных запусков ИИ:</div>
          <div v-if="user.llm_runs_limits_count === -1">
            Неограниченно
          </div>
          <div v-else>
            {{user.llm_runs_limits_count}}
          </div>
        </div>
      </div>

      <div class="my-4">
        <h3>HeadHunter</h3>
        <div v-if="employerId" class="mt-2">
          <div>
            Профиль: {{ $api.hh.employerData.first_name }} {{ $api.hh.employerData.last_name }}
          </div>
          <div>
            Компания: {{ $api.hh.employerData.employer.name }}
          </div>
          <div class="mt-4">
            <v-btn depressed color="#d6001c" dark @click="disconnectHeadHunter">Отключить HeadHunter</v-btn>
          </div>
        </div>
        <div v-else class="mt-2">
          <v-btn depressed color="#d6001c" dark @click="authHeadHunter">Подключить HeadHunter</v-btn>
        </div>
      </div>

      <div class="my-4" v-if="$store.persist.d.role === 'dev'">
        <h3>Проекты</h3>
        <div class="mt-2">
          <v-btn
            @click="loadProjects"
            depressed
            color="primary"
          >
            Загрузить проекты
          </v-btn>
          <input ref="importInput"
                 type="file"
                 :multiple="false"
                 @change="onSelectedDocument"
                 style="display: none;"
          />
          <v-btn
            @click="saveProjects"
            depressed
            color="primary"
            class="ml-2"
          >
            Сохранить проекты
          </v-btn>
        </div>
      </div>

      <template v-if="$store.persist.d.isDevMode">
        <CardLink
          title="Dev"
          description="Development tools"
          icon="mdi-xml"
          to="/dev"
          class="mb-4 mx-1"
          :dense="true"
          :block="true"
        />
      </template>
    </LayoutOneColumn>
</template>

<script>
import LayoutOneColumn from '@/ui/layouts/LayoutOneColumn';
import CardLink from "@/ui/components/CardLink.vue";

export default {
  name: 'PageProfile',
  components: {CardLink, LayoutOneColumn},
  data: () => ({
    user: null
  }),
  methods: {
    removeLocalData() {
      this.$api.app.openConfirmDialog({
        title: 'Удаление локальных данных',
        text: 'Вы уверены, что хотите удалить данные из локального хранилища?',
        ok: () => {
          this.$api.main.cleanLocalData();
          window.location.reload();
        },
      });
    },
    signOut() {
      this.$api.app.openConfirmDialog({
        title: 'Выход из аккаунта',
        text: 'Вы уверены, что хотите выйти из аккаунта?',
        ok: () => {
          this.$api.hh.disconnect();
          this.$api.auth.signOut();
          this.$router.push('/login').catch(() => {
          });
        },
      });
    },

    loadProjects() {
      this.$refs.importInput.click();
    },
    downloadObjectAsJson(exportObj, exportName){
      const dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(exportObj, null, 2));
      const downloadAnchorNode = document.createElement('a');
      downloadAnchorNode.setAttribute("href",     dataStr);
      downloadAnchorNode.setAttribute("download", exportName + ".json");
      document.body.appendChild(downloadAnchorNode); // required for firefox
      downloadAnchorNode.click();
      downloadAnchorNode.remove();
    },
    saveProjects() {
      this.downloadObjectAsJson(this.$store.main.s.projects, 'projects-jobs');
    },
    onSelectedDocument() {
      const isValidFile = this.$refs.importInput.files && this.$refs.importInput.files.length !== 0;
      if (!isValidFile) {
        this.$api.app.snackError('Invalid');
        return;
      }

      const file = this.$refs.importInput.files[0];

      this.$refs.importInput.files = null;
      this.$refs.importInput.name = '';
      this.$refs.importInput.value = null;

      const fr = new FileReader();

      fr.addEventListener("load", () => {
        const data = JSON.parse(fr.result);
        this.$store.main.s.projects = data;
        this.$store.persist.s.projectsJobs = JSON.stringify(this.$store.main.s.projects);
        location.reload();
      });

      fr.readAsText(file);
    },

    authHeadHunter() {
      this.$api.hh.auth();
    },
    disconnectHeadHunter() {
      this.$api.hh.disconnect();
    },
  },
  computed: {
    employerId() {
      return this.$api.hh.employerId.value;
    },
    employer() {
      return this.$api.hh.employerData;
    },
  },
  async created() {
    // TODO: loading and error
    this.user = (await this.$api.main.getRequest('models/user/my')).data;
  },
  async mounted() {
    if (this.$route.query?.code) {
      await this.$api.hh.fetchToken(this.$route.query.code);
    }
  },
};
</script>

<style scoped>
</style>
