<template>
    <div
      v-if="vacancyData"
            class="card-button align-center my-2 pa-4"
            :class="cardClass"
            @click="$router.push(`/vacancies/${item.id}`)"
      v-ripple
    >
      <template v-if="vacancy && vacancy.source === 'hh'">
        <div class="d-flex flex-column">
          <div class="d-flex align-center mb-2">
<!--            <div v-if="item.createdAt" class="caption">{{new Date(item.createdAt).toLocaleString('ru')}}</div>-->
            <div v-if="vacancyData.created_at" class="caption">Дата размещения вакансии на HH: {{new Date(vacancyData.created_at).toLocaleString('ru')}}</div>
            <v-spacer/>
<!--            <div v-if="item.code" class="body-2 ml-2"><b>Code</b> {{ item.code }}</div>-->
<!--            <div v-if="item.extId" class="body-2 ml-2"><b>Ext ID</b> {{ item.extId }}</div>-->
<!--            <div v-if="item.status === 'draft'" class="caption ml-2">Черновик</div>-->
            <div v-if="shortId" class="caption">{{shortId}}</div>
          </div>
            <div class="d-flex align-center">
              <div class="d-flex align-center mb-1 mb-sm-3" style="min-height: 32px">
                <h2 v-if="item.name" class="flex-grow-1">{{ item.name }}</h2>
                <div v-else class="font-italic" style="font-size: 14px">Название вакансии не указано</div>
              </div>
              <v-spacer/>
<!--              <v-btn icon small>-->
<!--                <v-icon>mdi-delete</v-icon>-->
<!--              </v-btn>-->
            </div>
            <div
                    v-if="item.description"
                    class="mb-1 mb-sm-3"
            >
                {{ item.description }}
            </div>
        </div>

      <div class="d-flex font-weight-bold align-center">
        <div v-if="area">{{ area }}</div>
        <div v-if="area && experience" class="mx-2" style="font-size: 8px">●</div>
        <div v-if="experience">{{ experience }}</div>
        <div v-if="experience && salary" class="mx-2" style="font-size: 8px">●</div>
        <div v-if="salary">{{ salary }}</div>
      </div>

      <div v-if="vacancyData.employer && vacancyData.employer.name" class="mt-4">Компания: {{ vacancyData.employer.name }}</div>

        <div class="d-flex align-center" style="gap: 4px;">
<!--          <v-btn depressed :to="`/vacancies/${item.id}`">Открыть</v-btn>-->
          <div v-if="item?.User?.name">Автор: {{ author }}</div>
          <v-spacer/>
          <v-btn icon @click.stop="remove">
            <v-icon>mdi-delete</v-icon>
          </v-btn>
<!--          <div v-if="item.companyName">Компания: {{ item.companyName }}</div>-->
        </div>
      </template>

      <template v-else-if="vacancy">
        <div class="d-flex flex-column">
          <div class="d-flex align-center mb-2">
            <div v-if="vacancy.createdAt" class="caption">{{new Date(vacancy.createdAt).toLocaleString('ru')}}</div>
            <v-spacer/>
<!--            <div class="caption ml-2">Черновик</div>-->
          </div>
          <div class="d-flex align-center">
            <div class="d-flex align-center mb-1 mb-sm-3" style="min-height: 32px">
              <h2 v-if="vacancy.name" class="flex-grow-1">{{ vacancy.name }}</h2>
              <div v-else class="font-italic" style="font-size: 14px">Название вакансии не указано</div>
            </div>
          </div>
        </div>

        <div class="d-flex align-center" style="gap: 4px;">
          <!--          <v-btn depressed :to="`/vacancies/${item.id}`">Открыть</v-btn>-->
          <div v-if="item?.User?.name">Автор: {{ author }}</div>
          <v-spacer/>
          <v-btn icon @click.stop="remove">
            <v-icon>mdi-delete</v-icon>
          </v-btn>
          <!--          <div v-if="item.companyName">Компания: {{ item.companyName }}</div>-->
        </div>
      </template>
    </div>

  <div v-else
       class="card-button align-center my-2 pa-4"
       :class="cardClass"
       @click="$router.push(`/vacancies/${item.id}`)"
       v-ripple
  >
    <div class="d-flex flex-column">
      <div class="d-flex align-center mb-2">
                    <div v-if="item.createdAt" class="caption">{{new Date(item.createdAt).toLocaleString('ru')}}</div>
        <v-spacer/>
        <div class="caption ml-2">Черновик</div>
      </div>
      <div class="d-flex align-center">
        <div class="d-flex align-center mb-1 mb-sm-3" style="min-height: 32px">
          <h2 v-if="item.name" class="flex-grow-1">{{ item.name }}</h2>
          <div v-else class="font-italic" style="font-size: 14px">Название вакансии не указано</div>
        </div>
      </div>
    </div>

    <div class="d-flex align-center" style="gap: 4px;">
      <!--          <v-btn depressed :to="`/vacancies/${item.id}`">Открыть</v-btn>-->
      <div v-if="item?.User?.name">Автор: {{ author }}</div>
      <v-spacer/>
      <v-btn icon @click.stop="remove">
        <v-icon>mdi-delete</v-icon>
      </v-btn>
      <!--          <div v-if="item.companyName">Компания: {{ item.companyName }}</div>-->
    </div>
  </div>
</template>

<script>
export default {
  name: "CardVacancy",
  props: ['item'],
  computed: {
    cardClass() {
      return (this.$vuetify.breakpoint.smAndUp ? '' : 'card-button-mobile') + ' ' +
        (this.$vuetify.theme.isDark ? 'theme--dark' : 'theme--light');
    },
    vacancy() {
      return this.item;
    },
    vacancyData() {
      return this.item?.ProjectRoleDescription?.data;
    },
    area() {
      return this.vacancyData.area.name;
    },
    experience() {
      return this.vacancyData.experience.name;
    },
    salary() {
      return this.$api.hh.salaryToText(this.vacancyData.salary)
    },
    shortId() {
      return this.vacancyData?.alternate_url?.split('/')?.at(-1);
    },
    author() {
      if (this.item.Company?.name) {
        return `${this.item.Company?.name} / ${this.item.User.name}`;
      }
      return this.item.User.name;
    },
  },
  methods: {
    remove() {
      this.$api.app.openConfirmDialog({
        title: 'Удаление вакансии',
        text: 'Вы уверены, что хотите удалить данную вакансию?',
        ok: () => {
          this.$emit('delete')
        },
      });
    },
  },
};
</script>

<style scoped>

.card-button {
    border-radius: 5px;
    //width: 100%;
  cursor: pointer;
}

/*.card-button-mobile {*/
/*    width: 100%;*/
/*    padding: 12px 8px 8px 12px !important;*/
/*    min-height: inherit;*/
/*}*/

.theme--light.card-button {
    border: thin solid rgba(0, 0, 0, 0.12);
}

.theme--dark.card-button {
    background-color: #1E1E1E;
    color: #FFFFFF;
    border: thin solid rgba(255, 255, 255, 0.12);
}
</style>
